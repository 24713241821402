<template>
    <div>
        <card-header title="New Payment Method" icon="fa-plus"/>

        <card-body>
            <card-list>
                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Method" label-position="inside">
                                <b-select expanded v-model="newCard.method">
                                    <option value="new">New Credit Card</option>
                                    <option :value="m.id" v-for="m in methods">Card ending in {{ m.last }} (exp. {{m.expiration.substr(0,2)}}/{{m.expiration.substr(2,4)}})</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                </container-list-item>
                <div v-if="newCard.method === 'new'">
                    <subheader-list-item icon="fa-align-left" title="New Credit Card Information"/>
                    <container-list-item padded>
                        <div class="columns">
                            <div class="column">
                                <b-field label="Credit Card Number" label-position="inside">
                                    <b-input v-model="newCard.number"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <b-field label="Expiration Month" label-position="inside">
                                    <b-input v-model="newCard.exp_month" placeholder="MM"></b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field label="Expiration Year" label-position="inside">
                                    <b-input v-model="newCard.exp_year" placeholder="YYYY"></b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field label="Code" label-position="inside">
                                    <b-input v-model="newCard.code"></b-input>
                                </b-field>
                            </div>
                        </div>
                    </container-list-item>
                </div>
            </card-list>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="false" @click="submit"><i class="fas fa-plus mr-3"></i>New Payment Method</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardContainer from '../../TIER/components/CardContainer';

    import {client as http} from '../../http_client';
    import CardFooter from "../../TIER/components/CardFooter";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";

    import CardBody from "../../TIER/components/CardBody";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    export default {
        props: ['card', 'props'],
        components: {ContainerListItem, CardBody, SubheaderListItem, CardList, CardFooter, CardContainer, CardHeader},
        data: function() {
            return {
                newCard: {
                    method: 'new'
                },
                dirty: null,
                methods: []
            };
        },
        methods: {
            loadMethods(force) {
                this.$emit('loading', true);
                http.get('/api/payment_plans/' + this.props.planId + '/methods', {force}).then(response => {
                    this.methods = response.data;
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.post('/api/payment_plans/' + this.props.planId + '/method', this.newCard, {force: true}).then(response => {
                    this.$reloadCard('payment-plan', {planId: this.props.planId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {
                    window.alert(err.response.data);
                    window.alert('An error occurred adding this payment method.  Be careful re-attempting this as it might be creating additional, unnecessary records.');
                    this.$emit('loading', false);
                });
            }
        },
        watch: {
            'props.planId': function() {
                this.dirty = null;
                this.loadPlan();
            },
            'newCard': {
                deep: true,
                handler: function() {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done creating this card? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.newCard.reg_id = this.props.regId;
            this.newCard.plan_id = this.props.planId;
            this.loadMethods();
        }
    };
</script>
